<template>
  <div>    
    <div class="card card-custom h-100">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Edit Provinsi
          </h3>
        </div>
      </div>

      <perfect-scrollbar
      class="scroll"
      style="max-height: 92%; position: relative;"
      >

        <!--begin::Form-->
        <div class="card-body">
          <ValidationObserver ref="formAdd">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            
            <validationProvider name="Kode" rules="required|numeric|max_value:99|length:2" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-1"
                label="Kode:"
                label-for="input-1"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-1"
                  class="form-control form-control-solid"
                  v-model="form.provinsi"
                  required
                  readonly
                  placeholder="Kode"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>
            
            <validationProvider name="Provinsi" rules="required" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-2"
                label="Nama Provinsi:"
                label-for="input-2"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-2"
                  class="form-control form-control-solid"
                  v-model="form.uraian"
                  required
                  placeholder="Nama Provinsi"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>

            <validationProvider name="BPS Code" rules="numeric|max_value:99|length:2" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-3"
                label="BPS Code:"
                label-for="input-3"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-3"
                  class="form-control form-control-solid"
                  v-model="form.bpscode"
                  placeholder="BPS Code"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>

            <validationProvider name="Latitude" rules="double" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-4"
                label="Latitude:"
                label-for="input-4"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-4"
                  class="form-control form-control-solid"
                  v-model="form.lat"
                  placeholder="Latitude"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>

            <validationProvider name="Longitude" rules="double" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-5"
                label="Longitude:"
                label-for="input-5"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-5"
                  class="form-control form-control-solid"
                  v-model="form.lng"
                  placeholder="Longitude"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>
            
            <div class="mt-10">
              <button 
              @click="onSubmit" 
              ref="kt_submit"
              class="btn btn-primary font-weight-bold">
                <i class="la la-save"></i>
                Perbarui
              </button>
              &nbsp;
              <button
              type="button"
              @click="onReset"
              class="btn btn-warning font-weight-bold">
                <i class="la la-recycle"></i>
                Reset
              </button>
              &nbsp;
              <button
              type="button"
              @click="closeDialog()"
              class="btn btn-clean font-weight-bold">
                <i class="la la-close"></i>
                Close
              </button>
            </div>
            
          </b-form>
          </ValidationObserver>     
        </div>
      </perfect-scrollbar>

    </div>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import WilayahService from "@/core/services/api/master/wilayah.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-wilayah-provinsi-edit",
  props: {
    idWL: {
      required: true
    }
  },
  data() {
    return {
      tabIndex: 0,
      dataOri: null,
      form: {
        provinsi: this.idWL,
        uraian: null,
        bpscode: null,
        lat: null,
        lng: null,
      },
      show: true
    }
  },
  methods: {   
    closeDialog() {
        return this.$emit('close-modal', {status:true, refresh: false})
    },
    getData () {
      WilayahService.getProvinsiSingle({ provinsi: this.idWL })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data
        })
        .catch((err) => ErrorService.status(err))
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            WilayahService.updateProvinsi(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    if (res.data.status) {
                      return this.$emit('close-modal', {...res.data, refresh: true})
                    }
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form = JSON.parse(this.dataOri)
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data WIlayah Provinsi" }]);
    this.getData()
  },
};
</script>

<template>
  <div>
    
    <div class="card card-custom h-100">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Edit Kecamatan
          </h3>
        </div>
      </div>

      <perfect-scrollbar
      class="scroll"
      style="max-height: 92%; position: relative;"
      >

        <!--begin::Form-->
        <div class="card-body">
          <ValidationObserver ref="formAdd">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">                
            <validationProvider name="Provinsi" rules="required" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-2"
                label="Provinsi:"
                label-for="input-2"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-select2
                  :id="`input-2`"
                  v-model="form.provinsi"
                  :options="opsProvinsi"
                  required
                  variant="solid"
                  readonly
                  disabled
                  :state="valid"
                ></b-form-select2>
              </b-form-group>
            </validationProvider>
          
            <validationProvider name="Kabupaten" rules="required" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-7"
                label="Kabupaten:"
                label-for="input-7"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-select2
                  :id="`input-7`"
                  v-model="form.kabupaten"
                  :options="opsKabupaten"
                  required
                  variant="solid"
                  readonly
                  disabled
                  :state="valid"
                ></b-form-select2>
              </b-form-group>
            </validationProvider>

            <validationProvider name="Kode" rules="required|numeric|max_value:999999|length:6" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-1"
                label="Kode:"
                label-for="input-1"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-1"
                  class="form-control form-control-solid"
                  v-model="form.kecamatan"
                  required
                  readonly
                  disabled
                  placeholder="Kode"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>

            <validationProvider name="Kecamatan" rules="required" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-3"
                label="Nama Kecamatan:"
                label-for="input-3"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-3"
                  class="form-control form-control-solid"
                  v-model="form.uraian"
                  required
                  placeholder="Nama Kecamatan"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>

            <validationProvider name="BPS Code" rules="numeric|max_value:999999|length:6" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-4"
                label="BPS Code:"
                label-for="input-4"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-4"
                  class="form-control form-control-solid"
                  v-model="form.bpscode"
                  required
                  placeholder="BPS Code"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>

            <validationProvider name="Latitude" rules="double" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-6"
                label="Latitude:"
                label-for="input-6"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-6"
                  class="form-control form-control-solid"
                  v-model="form.lat"
                  placeholder="Latitude"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>

            <validationProvider name="Longitude" rules="double" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-5"
                label="Longitude:"
                label-for="input-5"
                :invalid-feedback="errors[0]"
                :state="valid"
              >
                <b-form-input
                  autocomplete="off"
                  id="input-5"
                  class="form-control form-control-solid"
                  v-model="form.lng"
                  placeholder="Longitude"
                  :state="valid"
                ></b-form-input>
              </b-form-group>
            </validationProvider>
            
            <div class="mt-10">
              <button 
              @click="onSubmit" 
              ref="kt_submit"
              class="btn btn-primary font-weight-bold">
                <i class="la la-save"></i>
                Perbarui
              </button>
              &nbsp;
              <button
              type="button"
              @click="onReset"
              class="btn btn-warning font-weight-bold">
                <i class="la la-recycle"></i>
                Reset
              </button>
              &nbsp;
              <button
                type="button"
                @click="closeDialog()"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-close"></i>
                Close
              </button>
            </div>
            
          </b-form>
          </ValidationObserver> 
        </div>
      </perfect-scrollbar>
    </div>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import WilayahService from "@/core/services/api/master/wilayah.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-wilayah-kecamatan-edit",
  props: {
    idWL: {
      required: true
    },
  },
  data() {
    return {
      loadD: true,
      dataOri: null,
      form: {
        provinsi: null,
        kabupaten: null,
        kecamatan: this.idWL || null,
        uraian: null,
        bpscode: null,
        lat: null,
        lng: null,
      }, 
      opsProvinsi: [
        { text: 'Silahkan Pilih Provinsi', value: null },
      ],
      opsKabupaten: [
        { text: 'Silahkan Pilih Kabupaten', value: null },
      ],
      show: true
    }
  },
  watch: {
    'form.provinsi' (val, old) {
      if (val != old) {
        if (val == null) {
          this.opsKabupaten= [{
            value: null,
            text: "Silahkan pilih Kabupaten"
          }]
          return
        }

        if (this.form.kabupaten && !this.loadD) {
          this.form.kabupaten = null
          this.form.kecamatan = null
        }
        this.getKabupaten({ provinsi: val, aks: 1 })
      }
    },
  },
  methods: {
    closeDialog() {
        return this.$emit('close-modal', {status:true, refresh: false})
    },
    getProvinsi() {
      WilayahService.getProvinsi({ aks: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsProvinsi = [{
                  value: null,
                  text: "Silahkan Pilih Provinsi"
                }]
                return;
            }

            this.opsProvinsi = res.data.data
            this.opsProvinsi.push({
              value: null,
              text: "Silahkan Pilih Provinsi"
            })
        })
        .catch((err) => {
          this.opsProvinsi = [{
            value: null,
            text: "Tidak ada Provinsi"
          }]
          ErrorService.status(err)
        })
    },
    getKabupaten(param) {
      const fault = [{
        value: null,
        text: "Silahkan pilih Kabupaten"
      }]

      if (!param.provinsi) {
        this.opsKabupaten = fault 
        return;
      }
      
      WilayahService.getKabupaten(param)
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKabupaten = fault 
                return;
            }

            this.opsKabupaten = [ ...res.data.data, ...fault ]
        })
        .catch((err) => {
          this.opsKabupaten = [{
            value: null,
            text: "Tidak ada Kabupaten"
          }]
          ErrorService.status(err)
        })
    },
    getData () {
      WilayahService.getKecamatanSingle({ id: this.idWL })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data
            setTimeout(() => {
              this.loadD = false
            }, 500);
        })
        .catch((err) => ErrorService.status(err))
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            WilayahService.updateKecamatan(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    if (res.data.status) {
                      return this.$emit('close-modal', {...res.data, refresh: true})
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form = JSON.parse(this.dataOri)
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Hatchery" }]);
    this.getData()
    this.getProvinsi()
  },
};
</script>